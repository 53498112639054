
import { MenuItem } from './MenuInterfaces';
const menu: MenuItem[] = [
  // {
  //   text: "Dashboard",
  //   link: "/dashboard",
  // },
  {
    text: "Party Master",
    link: "/party-list",
    // subMenu: [
    //   {
    //     text: "Buyer",
    //     link: "/buyer-onboarding",
    //   },
    //   {
    //     text: "Supplier",
    //     link: "/supplier-onboarding",
    //   },
      
    // ],
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Material Categories",
        link: "/material-categories",
      },
      {
        text: "Material Library",
        link: "/material-library",
      },
      {
        text: "UOM Master",
        link: "/uom-master",
      },
      // {
      //   text: "Document Types",
      //   link: "/document-types",
      // },
    ],
  },
  {
    text: "Enquiries",
    link: "/enquiries",
  },
  
];
export default menu;
