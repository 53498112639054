import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import misc from "../../../api/misc/miscService";
import CropperComponent from "./CropperComponent"; // Adjust the path as necessary
import { Icon } from "../../../components/Component";
import mastersServices from "../../../api/masters/mastersService";
import FormSelect from "../../form-components/SelectComponent";
import FormMultiSelect from "../../form-components/MultiSelectComponent";

const GeneralInformation_Buyer: React.FC = (props: any) => {
  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [partyType, setPartyTypeOptions] = useState([
    { label: "Buyer", value: "Buyer" },
    { label: "Seller", value: "Seller" },
  ]);
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const {
    handleSubmit,
    watch,
    setValue,
  } = useFormContext();
  const FullFormDetails = watch();
  const partyTypeValue = watch("partyType");
  const orgLogoValue = watch("orgLogo");
  const toggleModal = () => setModal(!modal);

  const handleImageSave = (image: string) => {
    setValue("orgLogo", image);
    toggleModal();
  };

  useEffect(() => {
    console.log("orgLogoValue", orgLogoValue);
    setCroppedImage(orgLogoValue);
  }, [orgLogoValue]);

  const getMaterialCategories = async () => {
    try {
      const materialCategories = await mastersServices.getMaterialCategories();

      if (materialCategories.status && materialCategories.data.length > 0) {
        const transformedData = materialCategories.data.map((item: any) => ({
          value: item.id,
          label: item.category,
        }));
        setCategory(transformedData);
      }
    } catch (error: any) {}
  };

  const getContructionsMaterialTypes = async () => {
    try {
      const matCats = await misc.getContructionsMaterialTypes();
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setMaterialTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  const submitForm = (data: any) => {
    props.next(data);
  };

  const getOrgTypes = async () => {
    try {
      const orgTypes = await misc.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  useEffect(() => {
    getOrgTypes();
    getMaterialCategories();
    getContructionsMaterialTypes();
  }, []);
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="orgName"
              label="Organisation Name"
              required={true}
              disabled={FullFormDetails?.gstinVerified == "true"}
              type="text"
              minLength={2}
              // maxLength={30}
              placeholder="Enter Organisation name"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="orgType"
              label="Organisation Type"
              options={orgTypeOptions}
              required={false}
              placeholder="-Select-"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="partyType"
              label="Party Type"
              options={partyType}
              required={true}
              placeholder="-Select-"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          {partyTypeValue == "Seller" && (
            <>
              <Col lg="4">
                <FormMultiSelect
                  name="materials"
                  label="Material Type"
                  options={materialTypes}
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
              <Col lg="4">
                <FormMultiSelect
                  name="categories"
                  label="Supply Category"
                  options={category}
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
            </>
          )}
          <div style={{ display: "none" }}>
            <FormInput
              name="orgLogo"
              label="Organisation Logo"
              required={false}
              type="text"
              placeholder="Upload Logo"
            />
          </div>
          <Col lg="4" style={{ marginTop: "28px", display: "flex" }}>
            {!croppedImage && (
              <Button color="btn-dim btn" onClick={toggleModal}>
                <span>Upload Logo </span>
                <Icon name="upload-cloud" />
              </Button>
            )}
            {croppedImage && (
              <div style={{ marginTop: "10px" }}>
                <div
                  style={{
                    marginLeft: "25px",
                    height: "60px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={croppedImage}
                    alt="Cropped"
                    style={{ height: "60px" }}
                  />
                </div>
                <div>
                  <Button color="btn-dim btn" onClick={toggleModal}>
                    <span>Change</span>
                    <Icon
                      name="upload-cloud"
                      className="pointer"
                      onClick={toggleModal}
                    />
                  </Button>
                  <Button
                    color="btn-dim btn"
                    onClick={() => {
                      setCroppedImage(null);
                      setValue("orgLogo", null);
                    }}
                  >
                    <span>Cancel</span>
                    <Icon
                      name="cross"
                      className="pointer"
                      onClick={() => {
                        setCroppedImage(null);
                        setValue("orgLogo", null);
                      }}
                    />
                  </Button>
                </div>
              </div>
            )}
            <FormInput name="orgLogo" type="hidden" label="" />
          </Col>
        </Row>
        <CropperComponent
          modal={modal}
          toggle={toggleModal}
          onSave={handleImageSave}
        />

        <Row className="gy-4 mt-1">
          <Col>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                console.log("clicked", FormData);
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default GeneralInformation_Buyer;
